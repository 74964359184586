<template>
  <div class="uk-background-muted">
    <div class="uk-padding">
      <p class="uk-text-lead">Account Block Detail</p>

      <table class="uk-table uk-table-divider" v-if="block">
        <tbody class="uk-background-default">
          <tr v-if="block.height > 0">
            <td>Height</td>
            <td>
              <ul class="uk-iconnav">
                <li>
                  <span class="uk-margin-small-top">{{ block.height }}</span>
                </li>
                <li>
                  <router-link
                    style="display: inline-block"
                    v-if="block.height > 1"
                    :to="
                      '/tx/' + block.address + '/' + (Number(block.height) - 1)
                    "
                    uk-icon="chevron-left"
                  />
                </li>
                <li>
                  <router-link
                    style="display: inline-block"
                    :to="
                      '/tx/' + block.address + '/' + (Number(block.height) + 1)
                    "
                    uk-icon="chevron-right"
                  />
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>Hash</td>
            <td>{{ block.hash }}</td>
          </tr>
          <tr v-if="block.primaryBlockHash">
            <td>Primary Hash</td>
            <td>
              <v-link
                prefix="/tx/"
                :value="block.primaryBlockHash"
                :full="true"
              />
            </td>
          </tr>
          <tr v-if="block.primaryBlockHeight">
            <td>Primary Height</td>
            <td>{{ block.primaryBlockHeight }}</td>
          </tr>
          <tr>
            <td>Previous Hash</td>
            <td>
              <v-link prefix="/tx/" :value="block.previousHash" :full="true" />
            </td>
          </tr>
          <tr>
            <td>Address</td>
            <td>
              <v-link prefix="/account/" :value="block.address" :full="true" />
            </td>
          </tr>
          <tr>
            <td>BlockType</td>
            <td>{{ blockTypeText(block.blockType) }}</td>
          </tr>
          <tr v-if="isReceive(block.blockType)">
            <td>SendBlockHash</td>
            <td>
              <v-link prefix="/tx/" :value="block.sendBlockHash" :full="true" />
            </td>
          </tr>
          <tr v-if="!isReceive(block.blockType)">
            <td>ReceiveBlockHash</td>
            <td>
              <v-link
                prefix="/tx/"
                :value="block.receiveBlockHash"
                :full="true"
              />
            </td>
          </tr>
          <tr>
            <td>Time</td>
            <td>{{ new Date(block.timestamp * 1000).toLocaleString() }}</td>
          </tr>
          <tr>
            <td>Confirmations</td>
            <td>{{ block.confirmations }}</td>
          </tr>
          <tr>
            <td>FirstSnapshotHash</td>
            <td>
              <v-link
                prefix="/snapshot/"
                :value="block.firstSnapshotHash"
                :full="true"
              />
            </td>
          </tr>
          <tr v-if="isReceive(block.blockType)">
            <td>FromAddress</td>
            <td>
              <v-link
                prefix="/account/"
                :value="block.fromAddress"
                :full="true"
              />
            </td>
          </tr>
          <tr v-if="!isReceive(block.blockType)">
            <td>ToAddress</td>
            <td>
              <v-link
                prefix="/account/"
                :value="block.toAddress"
                :full="true"
              />
            </td>
          </tr>
          <tr v-if="block.triggeredSendBlockList">
            <td>Triggered Blocks</td>
            <td>
              <tr v-for="item in block.triggeredSendBlockList" :key="item.hash">
                <v-link prefix="/tx/" :value="item.hash" :full="true" />
              </tr>
            </td>
          </tr>

          <tr>
            <td>Token</td>
            <td>
              {{ block.tokenInfo.tokenSymbolView }}
            </td>
          </tr>
          <tr>
            <td>Amount</td>
            <td>{{ block.amount }}</td>
          </tr>
          <tr v-if="block.vmLogHash">
            <td>Event Log</td>
            <td>
              <v-link
                :prefix="'/account/' + block.address + '/log/'"
                :value="block.vmLogHash"
                :full="true"
              />
              <!-- {{ block.vmLogHash }} -->
            </td>
          </tr>
          <tr v-if="block.data">
            <td>Data</td>
            <td>
              <v-blockdata
                :value="block.data"
                :blockType="block.blockType"
                :to="block.toAddress"
              ></v-blockdata>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { atos, blockTypeText, isReceive, tokenView } from '@/utils/_';
import { nullToken } from '@/utils/consts';
import VLink from '@/components/Link';
import VBlockdata from '@/components/Blockdata';

export default {
  beforeRouteEnter(to, from, next) {
    const { hash, address, height } = to.params;
    next(vm => vm.getBlock(hash, address, height));
  },
  beforeRouteUpdate(to, from, next) {
    const { hash, address, height } = to.params;
    this.getBlock(hash, address, height).then(() => next());
  },
  data() {
    return {
      block: null
    };
  },
  methods: {
    atos,
    blockTypeText,
    isReceive,
    async getAccountBlock(hash, address, height) {
      if (hash) {
        return this.$api.request('ledger_getAccountBlockByHash', hash);
      } else {
        return this.$api.request(
          'ledger_getAccountBlockByHeight',
          address,
          height
        );
      }
    },
    async getBlock(hash, address, height) {
      const block = await this.getAccountBlock(hash, address, height);
      if (!block.tokenInfo) {
        block.tokenInfo = nullToken;
      } else {
        block.tokenInfo.tokenSymbolView = tokenView(
          block.tokenInfo.tokenSymbol,
          block.tokenInfo.index
        );
      }
      if (block.data) {
        block.hexData = Buffer.from(block.data, 'base64').toString('hex');
      }
      block.amount = atos(block.amount, block.tokenInfo.decimals);

      if (block.height === '0') {
        const completeBlock = await this.$api.request(
          'ledger_getCompleteBlockByHash',
          hash
        );
        if (completeBlock) {
          block.primaryBlockHash = completeBlock.hash;
          block.primaryBlockHeight = completeBlock.height;
        }
      }
      this.block = Object.seal(block);
    }
  },
  components: {
    VLink,
    VBlockdata
  }
};
</script>
